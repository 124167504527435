// // To see this message, add the following to the `<head>` section in your
// // views/layouts/application.html.erb
// //
// //    <%= vite_client_tag %>
// //    <%= vite_javascript_tag 'application' %>

console.log("Starting ExamTrack")

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
import Turbolinks from "turbolinks"
import * as  Rails  from "@rails/ujs"
window.Rails = Rails
import "bootstrap";
import 'jqueryui';

import select2 from 'select2';
import 'select2/dist/css/select2.min.css';
select2();
window.$ = jQuery;

import "../stylesheets/application.scss";
import 'chart.js';
import datepicker from 'js-datepicker';
import 'js-datepicker/src/datepicker.scss';


import 'bootstrap4-toggle';
import 'blueimp-file-upload/js/jquery.fileupload';
import 'structured-filter/js/structured-filter';
import 'structured-filter/css/structured-filter.css';
import 'bootstrap4-toggle/css/bootstrap4-toggle.min.css';


import '../utils/structured-filter';
import 'structured-filter'
import '../utils/context';
import '../utils/examtrack_filter';

import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';
window.Turbolinks = Turbolinks;

Rails.start();
Turbolinks.start();

import "../controllers/index";

$(document).on('turbolinks:before-cache', function() {     // this approach corrects the select 2 to be duplicated when clicking the back button
    $('[data-et-select]').select2('destroy');
    $('[data-toggle="tooltip"]').tooltip('hide');
} );
//
document.addEventListener("turbolinks:load", () => {

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover({html:true});
    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });

    // Toggle side menu.
    $("#menu-toggle").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });


    // // todo: Style this object.
    const pickers = multiPickers('.date-picker', {
        startDay: 1,
        formatter: (input, date, instance) => {
            var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
            var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
            var yyyy = date.getFullYear();
            // This will display the date as `01/01/2019`.
            input.value = dd + '/' + MM + '/' + yyyy
        },
    });


    // change chevron on select of expand filter.
    $(".btn[data-toggle='collapse'].filter-collapse-button").click(function() {
        var div = $(this).contents();
        if(div.hasClass("fa-chevron-down")) {
            div.removeClass("fa-chevron-down");
            div.addClass("fa-chevron-up");
        } else {
            div.removeClass("fa-chevron-up");
            div.addClass("fa-chevron-down");
        }
    });

    // Automatically manage select2 objects in the page.
    const s2elements = $('[data-et-select]');
    s2elements.each(function() {
        $(this).select2({
            theme: 'bootstrap4',
            allowClear: true,
            placeholder: "Please Select",
            templateResult: function(data, container) {
                if(data.bg_colour) {
                    $(container).css({"background-color":data.bg_colour});
                    $(container).css({"color": "#ffffff"});
                }
                return data.text;
            },
            ajax: {
                delay: 180,// wait 180 milliseconds before triggering the request
                url: $(this).attr("data-select-url"),
                dataType: 'json',
                data: function (params) {
                    if($(this).attr("data-select-query") != undefined || $(this).attr("data-select-query") != null) {
                        var query = {
                            q: {
                                [$(this).attr('data-select-query')]: params.term,
                            },
                            page: params.page || 1
                        }
                    } else {
                        var query = {
                            q: {
                                name_cont: params.term,
                            },
                            page: params.page || 1
                        }
                    }

                    return query;
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.results,
                        pagination: {
                            more: (data.pagination.current) < data.pagination.pages
                        }
                    };
                }
            }
        });
    });


    var examtrack_filters = document.querySelectorAll("[data-filter]");
    examtrack_filters.forEach(function(el) {
        if(el.dataset.display === "true") {
            et_buildSimpleFilter(el, parseInt(el.id.substr(7)) );
        }
    });


    context.init({preventDoubleContext: false});

    $('body').on('click', '[data-toggle="modal"]', function(e){
        if($(this).data("remoteuri")) {
            $($(this).data("target") + ' .modal-content').load($(this).data("remoteuri"));
        }
    });
});

// addEventListener("turbo:submit-start", ({ target }) => {
//     for (const field of target.elements) {
//         field.disabled = true
//     }
// })

// Expects that the form is named "primary_object-form" for example "account-form"

// $(document).on("ajax:success", function(event) {
//    console.log("success")
//     console.log(event);
// });

$(document).on("ajax:error", function(event) {
    if(event.detail[1] === "Unauthorized") {
        var e = JSON.parse(event.detail[2].responseText);
        if (e["error"] === "Your session expired. Please sign in again to continue.") {
            alert("Your session has timed out. Please refresh this page to log back in again")
        }
    }

    if (event.detail[1] === "Unprocessable Entity") {
        // if the form name is defined in the detail (wherein the devil may lay) then
        // clear all of the current errors from the form:
        if (event.originalEvent.target !== null && event.originalEvent.target !== undefined) {
            var form = event.originalEvent.target;
            var singular_model_name = event.originalEvent.target.id.split("-")[0];
            $(form).find('*').removeClass('is-invalid');
            $(form).find('.invalid-feedback').remove();

            try {
                const errors = JSON.parse(event.detail[2].responseText);
                $.each(errors, function (i, item) {
                    if (i.includes(".")) {
                        if ((i.split(".").length - 1) > 1) {
                            return
                        }
                        // Work on nested form model
                        var field = i.split(".")[1];
                        var model_name = i.substr(0, i.indexOf("."));
                        var element = $("[id*='" + model_name + "_attributes_" + field + "']");
                    } else {
                        var element = $("[id$=" + singular_model_name + "_" + i + "]");
                    }
                    $(element).addClass('is-invalid');
                    $(element).after("<div class='invalid-feedback'>" + item.join('. ') + "</div>");
                });
            } catch (e) {
                return false;
            }
        }
    }
});

// Allow multiple date pickers to be used on a page.
function multiPickers(selector, options) {
    const elements = document.querySelectorAll(selector)
    return Array
        .from(elements)
        // Filter out the elements which have already been initialised
        .filter(e => e.getAttribute('data-initialised') != "true")
        .map(element => {
            var selectedDate = element.dataset.selectedDate;
            if (selectedDate !== undefined) {
                var newOptions = {...options};
                new Date("2012","02","12");
                newOptions.dateSelected  = new Date(parseInt(selectedDate.substring(6,10)),(parseInt(selectedDate.substring(3,5))-1),parseInt(selectedDate.substring(0,2)));
                // ^--- Don't forget that month is -1....
                datepicker(element, newOptions);
                // set the element as initialised.
                element.setAttribute('data-initialised', 'true');
            } else {
                datepicker(element, options);
            }
        })
}


function createSearchFilters(filterElement, data) {
    filterElement.find('*').not('#defaultSearchFilters').remove();
    var i = 0;
    for (const filter of data) {


        filterElement.append(
            "<div class='filter'>" +
            "<div class='filterLabel'>" + filter.field.label + "</div>" +
            "<div class='filterPredicate'>" + filter.operator.label + "</div>" +
            "<div class='filterValue'>" + filter.value.label + "</div>" +
            "<a class='filterRemove' onclick='$(\"#defaultSearchFilters\").structFilter(\"removeCondition\", " + i + ");'><i class='fa fa-times-circle'</i></a>" +
            "</div>"
        );
        var operatorName = filter.operator.value;

        filterElement.append(
            '<input type="hidden" name="q[' + filter.field.value + '_' + operatorName +']" id="' + filter.field.value + '_' + operatorName +'" value="' + filter.value.value + '">'
        );
        i = i + 1;
    }

    filterElement.append(
        '<textarea id="filterText" style="display:none;">' + JSON.stringify(data) + '</textarea>'
    );

}

!function(a){function f(a,b){if(!(a.originalEvent.touches.length>1)){a.preventDefault();var c=a.originalEvent.changedTouches[0],d=document.createEvent("MouseEvents");d.initMouseEvent(b,!0,!0,window,1,c.screenX,c.screenY,c.clientX,c.clientY,!1,!1,!1,!1,0,null),a.target.dispatchEvent(d)}}if(a.support.touch="ontouchend"in document,a.support.touch){var e,b=a.ui.mouse.prototype,c=b._mouseInit,d=b._mouseDestroy;b._touchStart=function(a){var b=this;!e&&b._mouseCapture(a.originalEvent.changedTouches[0])&&(e=!0,b._touchMoved=!1,f(a,"mouseover"),f(a,"mousemove"),f(a,"mousedown"))},b._touchMove=function(a){e&&(this._touchMoved=!0,f(a,"mousemove"))},b._touchEnd=function(a){e&&(f(a,"mouseup"),f(a,"mouseout"),this._touchMoved||f(a,"click"),e=!1)},b._mouseInit=function(){var b=this;b.element.bind({touchstart:a.proxy(b,"_touchStart"),touchmove:a.proxy(b,"_touchMove"),touchend:a.proxy(b,"_touchEnd")}),c.call(b)},b._mouseDestroy=function(){var b=this;b.element.unbind({touchstart:a.proxy(b,"_touchStart"),touchmove:a.proxy(b,"_touchMove"),touchend:a.proxy(b,"_touchEnd")}),d.call(b)}}}(jQuery);


window.createSearchFilters = createSearchFilters;
window.multiPickers = multiPickers;
window.datePicker = datepicker;

console.log('⚡️ ExamTrack Loaded')

